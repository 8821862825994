@function decimal-round($number, $digits: 0, $mode: round) {
  $n: 1;
  // $number must be a number
  @if type-of($number) != number {
    @warn '#{ $number } is not a number.';
    @return $number;
  }
  // $digits must be a unitless number
  @if type-of($digits) != number {
    @warn '#{ $digits } is not a number.';
    @return $number;
  } @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';
    @return $number;
  }
  @if $digits > 0 {
    @for $i from 1 through $digits {
      $n: $n * 10;
    }
  }
  @if $mode == round {
    @return round($number * $n) / $n;
  } @else if $mode == ceil {
    @return ceil($number * $n) / $n;
  } @else if $mode == floor {
    @return floor($number * $n) / $n;
  } @else {
    @warn '#{ $mode } is undefined keyword.';
    @return $number;
  }
}

@function size-vw($size) {
  $vp: 1920;
  $persent: $vp / 100;
  $vw: $size / $persent;

  @return #{decimal-round($vw, 5, ceil)}vw;
}

%clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

%bold {
  @at-root .Latin & {
    font-family: 'Bold';
  }
  @at-root .Arabic & {
    // font-family: 'Bold_ar';
    font-family: 'Bold';
  }
}
%regular {
  @at-root .Latin & {
    font-family: 'Regular';
  }
  @at-root .Arabic & {
    // font-family: 'Regular_ar';
    font-family: 'Regular';
  }
}
// %light {
//   @at-root .Latin & {
//     font-family: 'Light';
//   }
//   @at-root .Arabic & {
//     font-family: 'Light_ar';
//   }
// }

// @function size($size){
//     $rem: $size / $doc_size;
//     @return #{decimal-round($rem, 5, ceil)}rem;
// }

@function size-em($base_font, $size) {
  $em: $size / $base_font;
  @return #{decimal-round($em, 5, ceil)}em;
}

$doc_size: 10;
@function size($size) {
  $rem: $size / $doc_size;
  @return #{decimal-round($rem, 5, ceil)}rem;
}
